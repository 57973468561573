<script>
import PrintA4 from "@/components/print/defaults/PrintA4"

export default {
  components: {PrintA4},
  nome: 'RelatoriosBemListDefaultPrint',
  props: {
    data: {required: true},
    orientation: {
      type: String,
      default: 'portrait'
    },
    router: {required: true}
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {

    })
  },
  activated() {
  },
  created() {
  },
  deactivated() {
  },
  watch: {},
  methods: {
    handleClick(e) {
      if (e.target.matches('.click-open-bem')) {
        console.log('Abrindo bem ID ', e.target.dataset.id)
        let routeData = this.router.resolve({name: 'bens.show', params: {id: e.target.dataset.id}});
        window.open(routeData.href, '_blank');
      }
    }
  }
}
</script>

<template>
  <print-a4 :orientation="orientation" :is-loading="isLoading">
    <div @click="handleClick" v-if="data && data.template" v-html="data.template.template || data.template"></div>
  </print-a4>
</template>
